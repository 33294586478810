<template>
  <div class="worksDetail">
    <div class="cont a-fade-in">
      <div class="nav_title">
        <span class="parent_nav" @click="backPage">基地风采</span>
        <img class="nav_arrow" src="../../assets/imgs/nav_arrow_icon.png" alt />
        <span class="child_nav">【{{detailData.author}}】的作品</span>
      </div>
      <div class="line"></div>
      <div class="works_box">
        <div class="box_left" v-if="detailData.workVideo">
          <video-player
            class="video-player-box a-fade-in"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
          ></video-player>
          <img
            class="open_icon"
            @click="openViedo"
            v-if="openIcon"
            src="../../assets/imgs/video_big_icon.png"
            alt
          />
        </div>
        <div class="box_left" v-else>
          <img :src="QiNiuPath + detailData.workImg" alt />
        </div>
        <div class="box_right">
          <h2 class="title">{{detailData.workTitle}}</h2>
          <ul>
            <li class="works_infor">
              <span class="infor_left">作者</span>
              <span class="infor_right">{{detailData.author}}</span>
            </li>
            <li class="works_infor">
              <span class="infor_left">学校</span>
              <span class="infor_right">{{detailData.authorSchool}}</span>
            </li>
            <li class="works_infor">
              <span class="infor_left">指导老师</span>
              <span class="infor_right">{{detailData.tutor}}</span>
            </li>
            <li class="infor_left works_desc">作品说明</li>
            <li class="infor_right works_desc_txt">{{detailData.workIntro}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryWorksDetail } from "@/apis/EventCenter.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      detailData: {},
      playerOptions: {}, // video.js 配置,
      openIcon: true, //自定义视频播放icon
    };
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
    // video.js 配置
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    let id = this.$route.query.id;
    this.getDetail(id);
  },
  watch: {},
  methods: {
    getDetail(id) {
      let params = {
        id,
      };
      queryWorksDetail(params).then((res) => {
        if (res.data.status == 0) {
          let back = res.data.result;
          this.detailData = back;
          if (back.workVideo) {
            this.playerOptions = {
              // videojs options
              muted: true,
              language: "en",
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [
                {
                  type: "video/mp4",
                  src: this.QiNiuPath + this.detailData.workVideo,
                },
              ],
              width: "700px",
              height: "560px",
              poster: this.QiNiuPath + this.detailData.workImg,
              muted: false
            };
          }
        }
      });
    },
    backPage() {
      this.$router.back();
    },
    onPlayerPlay(player) {
      this.openIcon = false;
    },
    onPlayerPause(player) {
      this.openIcon = true;
    },
    openViedo() {
      this.player.play();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.worksDetail {

  .cont {
    width: 1200px;
    margin: 0 auto 177px auto;
    // border: 1px solid red;
    padding: 30px 30px 0 30px;
    .nav_title {
      display: inline-flex;
      align-items: center;
      .parent_nav {
        @include sc(14px, #9aa7b9);
        cursor: pointer;
        font-weight: bold;
      }
      .nav_arrow {
        @include wh(6px, 11px);
        margin: 0 10px 0 15px;
      }
      .child_nav {
        @include sc(14px, #333a48);
        font-weight: bold;
      }
    }
    .line {
      border: 1px dashed #e5eaf2;
      margin: 24px 0;
    }
    .works_box {
      display: flex;
      .box_left {
        margin-right: 96px;
        // @include wh(700px, 560px);
        height: 560px;
        position: relative;
        img {
          @include wh(700px, 560px);
        }
        .open_icon {
          @include wh(81px, 69px);
          @include ascenter();
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .box_right {
        overflow: hidden;
        .title {
          @include sc(28px, #1c222e);
          margin-bottom: 30px;
          font-weight: bold;
        }
        .works_infor {
          display: flex;
          margin-bottom: 17px;
          font-size: 14px;
          &:nth-of-type(3) {
            margin-bottom: 47px;
          }
        }
        .infor_left {
          color: #1c222e;
          font-weight: bold;
          display: inline-block;
          width: 60px;
          margin-right: 30px;
        }
        .infor_right {
          color: #606978;
          flex: 1;
        }
        .works_desc {
          margin-bottom: 12px;
        }
        .works_desc_txt {
          font-size: 12px;
        }
      }
    }
  }
}
</style>